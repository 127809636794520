import React, {FC, ReactNode, useEffect} from 'react';
import Button from "@mui/material/Button";

interface MainLayoutProps {
    children: ReactNode;
    mobile: boolean;
    fullWidth?: boolean
    submit?: boolean,
    click?: any
    enterPress?: boolean
    disabled?: boolean
}


const MyBtn: FC<MainLayoutProps> = ({
                                        children,
                                        click,
                                        mobile,
                                        fullWidth,
                                        submit,
                                        enterPress,
                                        disabled
                                    }) => {

    useEffect(() => {
        const globalKeyDownHandler = (event: KeyboardEvent) => {
            if (event.key === 'Enter' && enterPress && click !== undefined) {
                event.preventDefault();
                click();
            }
        };
        window.addEventListener('keydown', globalKeyDownHandler);
        return () => {
            window.removeEventListener('keydown', globalKeyDownHandler);
        };
    }, [click, enterPress]);

    return (
        <Button
            onClick={click}
            fullWidth={fullWidth && true}
            variant="outlined"
            type={submit ? "submit" : undefined}
            disabled={disabled}
            sx={{
                backgroundColor: disabled ? '#b9b9b9' : "#16A34A",
                color: '#ffffff',
                textTransform: 'capitalize',
                fontSize: 12,
                display: mobile ? {xs: 'none', md: 'flex'} : 'flex',
                ':hover': {
                    backgroundColor: '#15803D',
                }
            }}
        >
            {children}
        </Button>
    );
};

export default MyBtn;
