import React from 'react';

export const MasterCard = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="31" fill="none">
            <rect y=".002" width="50" height="30" rx="4" fill="#6B7280"/>
            <path d="M28.765 9 21 9.023 21.235 21 29 20.978 28.765 9Z" fill="#FF5F00"/>
            <path
                d="M21.86 14.994a7.914 7.914 0 0 1 2.928-6.3A8.006 8.006 0 0 0 19.843 7a7.855 7.855 0 0 0-7.84 8.027c.073 4.422 3.7 7.988 8.109 7.973A7.815 7.815 0 0 0 25 21.272a8.117 8.117 0 0 1-3.14-6.278Z"
                fill="#EB001B"/>
            <path
                d="M37.999 14.973A7.855 7.855 0 0 1 30.159 23a8.008 8.008 0 0 1-4.947-1.694c1.836-1.473 2.972-3.744 2.929-6.3A8.151 8.151 0 0 0 25 8.729 7.815 7.815 0 0 1 29.89 7c4.408-.015 8.035 3.573 8.109 7.973Z"
                fill="#F79E1B"/>
        </svg>
    );
};
