import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import createJob1 from '../../../public/createJob/createJob1.png'
import createJob2 from '../../../public/createJob/createJob2.png'
import createJob3 from '../../../public/createJob/createJob3.png'
import createJob4 from '../../../public/createJob/createJob4.png'
import createJob5 from '../../../public/createJob/createJob5.png'
import createJob6 from '../../../public/createJob/createJob6.png'
import createJob7 from '../../../public/createJob/createJob7.png'
import createJob8 from '../../../public/createJob/createJob8.png'

const ImageSlider = () => {
    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true, // Enable autoplay
        autoplaySpeed: 2000, // Set autoplay speed in milliseconds (e.g., 2000ms = 2 seconds)
        fade: true // Enable fade effect
      };

  const sliderContainerStyle = {
    width: '100%', // Adjust the width of the slider container
    maxWidth: '300px',
    height: 'auto', // Adjust the height of the slider container
    // boxShadow: "0px 4px 80px rgba(0, 0, 0, 0.3)", 
    boxShadow: "0px 4px 20px rgba(192, 192, 192, 0.6), 0px 4px 40px rgba(192, 192, 192, 0.6), 0px 4px 60px rgba(192, 192, 192, 0.6), 0px 4px 80px rgba(192, 192, 192, 0.6)",
    padding:'10px',
    borderRadius:'20px',
    justifyContent:'center',
    margin: 'auto', // Center horizontally
  };

  return (
    <div style={sliderContainerStyle}>
    <Slider {...settings}>
       {/* <div>
        <img src={createJob1} alt="createJob1" style={{ width: '100%', height: 'auto' }} />
      </div> */}
      <div>
        <img src={createJob2} alt="createJob2" style={{ width: '100%', height: 'auto' }}/>
      </div>
      <div>
        <img src={createJob3} alt="createJob3" style={{ width: '100%', height: 'auto' }}/>
      </div>
      <div>
        <img src={createJob4} alt="createJob4" style={{ width: '100%', height: 'auto' }}/>
      </div>
      <div>
        <img src={createJob5} alt="createJob5" style={{ width: '100%', height: 'auto' }}/>
      </div>
      <div>
        <img src={createJob6} alt="createJob6" style={{ width: '100%', height: 'auto' }}/>
      </div>
      <div>
        <img src={createJob7} alt="createJob7" style={{ width: '100%', height: 'auto' }}/>
      </div>
      <div>
        <img src={createJob8} alt="createJob8" style={{ width: '100%', height: 'auto' }}/>
      </div>
    </Slider>
    </div>
  );
};

export default ImageSlider;


