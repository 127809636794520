import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const useChangeLanguage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { i18n } = useTranslation();

    const changeLanguage = () => {
        const newLang = i18n.language === "en" ? 'fr' : 'en';
        i18n.changeLanguage(newLang);

        let newPath = location.pathname;
        const pathParts = newPath.split('/');

        if (pathParts.length > 1 && ['en', 'fr'].includes(pathParts[1])) {
            pathParts[1] = newLang;
        } else {
            pathParts.splice(1, 0, newLang);
        }

        newPath = pathParts.join('/');
        navigate(newPath);
    };

    return changeLanguage;
};

export default useChangeLanguage;
