import React, {lazy, Suspense, useEffect, useState} from 'react';
import {MainLayout} from "./component/MainLayout/MainLayout";
import {Route, Routes, useNavigate} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "./store/store";
import {clearErrorUser, fetchUser} from "./store/profileSlice/profileSlice";
import {clearErrorJob, fetchJobs} from "./store/jobsSlice/jobsSlice";
import {addMessage, addNotification, createMessages} from './store/messageSourceSlice/messageSource';
import {getInfo} from "./API/getInfo";
import {logout, updateStatus} from "./store/authSlice/authSlice";
import axios, {AxiosError} from "axios";
import {ScrollToTop} from "./component/ScrollToTop/ScrollToTop";
import {getUserProsSlice} from "./store/userPros/userProsSlice";
import "./component/UI/calendarStale.css"
import {LoadScript} from "@react-google-maps/api";
import {LinearProgress} from "@mui/material";
import {publicRout} from "./routing/publicRout";
import {adminRouts} from "./routing/adminRouts";
import {userRouts} from "./routing/userRouts";
import Cookies from 'js-cookie';
import {useLocation} from 'react-router-dom';
import {useTranslation} from "react-i18next";

const Login = lazy(() => import('./page/Login/Login').then(module => ({default: module.Login})));
const login = [
    {id: "/confirm", path: "/confirm", component: <Login/>},
]

type GoogleMapsLibrary = "places" | "geometry" | "drawing"
const googleMapsLibraries: GoogleMapsLibrary[] = ["places"];

function App() {
    const {i18n} = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    const API_URL = process.env.REACT_APP_API_URL || "";
    const keyMap = process.env.REACT_APP_GOOGLE_MAP || "";
    const history = useNavigate();
    const dispatch = useDispatch<AppDispatch>();

    const {isAuthenticated, authUserId, role} = useSelector((state: RootState) => state.auth);
    const {dataProfile} = useSelector((state: RootState) => state.profile);
    const {updateOffer} = useSelector((state: RootState) => state.offers);
    const {updateMessage} = useSelector((state: RootState) => state.messageSource);
    const {errorProfileMessage} = useSelector((state: RootState) => state.profile);
    const {messageSource, notificationSource} = useSelector((state: RootState) => state.messageSource);

    const [responseNotification, setResponseNotification] = useState<number | null>(null);
    const [responseMessage, setResponseMessage] = useState<ResponseMessage[]>([]);
    const [, setLoading] = useState<boolean>(true);
    const [, setError] = useState<AxiosError | Event | null>(null);

    useEffect(() => {
        if (isAuthenticated === true) {
            dispatch(fetchUser());
            dispatch(fetchJobs());
        }
        dispatch(clearErrorUser());
        dispatch(clearErrorJob());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, messageSource, notificationSource, updateOffer, updateMessage]);

    
    useEffect(() => {
        let eventSource: EventSource | null = null;
        if (isAuthenticated) {
            const startEventSource = () => {
                eventSource = new EventSource(`${API_URL}/sse/notify/${authUserId}`, {withCredentials: true});
                eventSource.onmessage = (event: MessageEvent) => {
                    const data = JSON.parse(event.data);
                    if (typeof data === "number") {
                        dispatch(addNotification(data));
                    } else {
                        dispatch(addMessage(data));
                    }
                };
                eventSource.onerror = (error) => {
                    // Reconnect logic: Attempt to reconnect after 5 seconds
                    if (eventSource && eventSource.readyState === EventSource.CLOSED) {
                        setTimeout(startEventSource, 5000);
                    }
                    setError(error);
                };
            };
            startEventSource();
        }

        return () => {
            // Check if eventSource is not null before trying to close it
            if (eventSource) {
                eventSource.close();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated]);

    useEffect(() => {
        if (isAuthenticated)
        getInfo({
            urlFragment: "notifications/all/unread",
            setResponse: setResponseNotification,
            setLoading: setLoading,
            setError: setError,
        })
    }, [isAuthenticated])

    useEffect(() => {
        if (isAuthenticated)
        getInfo({
            urlFragment: "chat/all/unread",
            setResponse: setResponseMessage,
            setLoading: setLoading,
            setError: setError,
        })
    }, [isAuthenticated])

    useEffect(() => {
        dispatch(getUserProsSlice());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated]);

    useEffect(() => {
        dispatch(addNotification(responseNotification))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [responseNotification])

    useEffect(() => {
        dispatch(createMessages(responseMessage));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [responseMessage])


    useEffect(() => {
        const errorMessage = errorProfileMessage?.error.message;
        if (["User not found", "Unauthorized"].includes(errorMessage)) {
            dispatch(logout());
            history(`/`);
            void axios.post(`${API_URL}/auth/logout`, {},
                {withCredentials: true})
            dispatch(clearErrorUser());
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorProfileMessage])


    useEffect(() => {
        if (dataProfile !== null) {
            dispatch(updateStatus({
                isEmailVerified: dataProfile.authStatus.isEmailVerified,
                role: dataProfile.authStatus.role,
                isPriceListSet: dataProfile.authStatus.priceListSet,
                isPhoneNumberVerified: dataProfile.authStatus.phoneNumberVerified,
                isPaymentMethodSet: dataProfile.authStatus.paymentMethodSet,
                isGovID1Verified: dataProfile.authStatus.govID1Verified,
                isGovID2Verified: dataProfile.authStatus.govID2Verified,
                isDeleted: dataProfile.authStatus.deleted,
                isSuspended: dataProfile.authStatus.suspended,
                isSuspendedPro: dataProfile.status.suspended,
                isRequestForVerification: dataProfile.authStatus.requestForVerification,
                isPro: dataProfile.authStatus.pro
            }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataProfile]);


    useEffect(() => {
        const cookieLang = Cookies.get('i18next') || 'en';
        if (location.pathname === '/') {
            i18n.changeLanguage(cookieLang);
            navigate(`/${cookieLang}`);
        }
    }, [location.pathname, navigate]);

    return (
        <MainLayout>
            <LoadScript
                googleMapsApiKey={keyMap}
                libraries={googleMapsLibraries}
                loadingElement={<LinearProgress/>}
                language={i18n.language === 'fr' ? "fr" : "en-US"}
                region={i18n.language === 'fr' ? "fr" : "US"}
            >
                <ScrollToTop/>
                <Suspense fallback={<LinearProgress/>}>
                    <Routes>
                        {publicRout.map(route => (
                            <Route key={route.id} path={`:lng${route.path}`} element={route.component}>
                                {route.children && route.children.map(childRoute => (
                                    <Route key={childRoute.id} path={childRoute.path} element={childRoute.component}/>
                                ))}
                            </Route>
                        ))}
                        {isAuthenticated === true && userRouts.map(route => (
                            <Route key={route.id} path={`:lng${route.path}`} element={route.component}>
                                {route.children && route.children.map(childRoute => (
                                    <Route
                                        key={childRoute.id}
                                        path={childRoute.path}
                                        element={childRoute.component}
                                    />
                                ))}
                            </Route>
                        ))}
                        {isAuthenticated === false ? login.map(route =>
                            <Route key={route.id} path={`:lng${route.path}`} element={route.component}/>) : null
                        }
                        {isAuthenticated === true && role === "admin" ? adminRouts.map(route => (
                            <Route key={route.id} path={`:lng${route.path}`} element={route.component}>
                                {route.children && route.children.map(childRoute => (
                                    <Route
                                        key={childRoute.id}
                                        path={childRoute.path}
                                        element={childRoute.component}
                                    />
                                ))}
                            </Route>
                        )) : null}
                    </Routes>
                </Suspense>
            </LoadScript>
        </MainLayout>
    );
}

export default App;
