import React from 'react';
import { Box, Card, CardContent, Typography, List, ListItem, ListItemText, Divider, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

const AffiliateProgramInfo = () => {
    const { t, i18n } = useTranslation();

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
            <Card sx={{ maxWidth: 600, boxShadow: 3, borderRadius: 2 }}>
                <CardContent>
                    <Typography variant="h5" gutterBottom align="center" color="primary">
                        {t('Affiliate.mainTitle')}
                    </Typography>

                    <Typography variant="body1" gutterBottom align="center" sx={{ color: 'text.secondary' }}>
                        {t('Affiliate.subMainTitle')}
                    </Typography>

                    <Divider sx={{ my: 2 }} />

                    <List>
                        <ListItem disablePadding>
                            <ListItemText
                                primary={<> 💸 {t('Affiliate.commissionRate')} </>}
                                secondary={t('Affiliate.commissionRateBody')}
                            />
                        </ListItem>
                        <Divider variant="inset" component="li" />

                        <ListItem disablePadding>
                            <ListItemText
                                primary={<> 🔍 {t('Affiliate.howItWorks')} </>}
                                secondary={
                                    <>
                                        <Typography variant="body2" gutterBottom>
                                            - {t('Affiliate.howItWorksBody')}
                                        </Typography>
                                        <Typography variant="body2">
                                            - {t('Affiliate.howItWorksExample')}
                                        </Typography>
                                    </>
                                }
                            />
                        </ListItem>
                        <Divider variant="inset" component="li" />

                        <ListItem disablePadding>
                            <ListItemText
                                primary={<> 📈 {t('Affiliate.ongoingEarnings')} </>}
                                secondary={
                                    <>
                                        <Typography variant="body2" gutterBottom>
                                            - {t('Affiliate.ongoingEarningsBody1')}
                                        </Typography>
                                        <Typography variant="body2">
                                            - {t('Affiliate.ongoingEarningsBody2')}
                                        </Typography>
                                        <Typography variant="body2">
                                            - {t('Affiliate.ongoingEarningsBody3')}
                                        </Typography>
                                    </>
                                }
                            />
                        </ListItem>
                        <Divider variant="inset" component="li" />

                        <ListItem disablePadding>
                            <ListItemText
                                primary={<> 💼 {t('Affiliate.passiveIncome')} </>}
                                secondary={t('Affiliate.passiveIncomeBody')}
                            />
                        </ListItem>
                    </List>

                    <Divider sx={{ my: 2 }} />

                    <Typography variant="body2" align="center" color="text.secondary">
                        {t('Affiliate.callToAction')}
                    </Typography>
                    <Box display="flex" justifyContent="center">
                        <Button
                            variant="contained"
                            color="primary"
                            href={`${i18n.language}/contact-us`}// Update with actual contact URL
                            sx={{ mt: 1, backgroundColor: "primary" }}
                        >
                            {t('Affiliate.contactUsButton')}
                        </Button>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    );
};

export default AffiliateProgramInfo;
