import { Box, Divider, Grid, useMediaQuery, useTheme } from '@mui/material';
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

export const ComparisonHome = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box
            sx={{
                // boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.15)',
                padding: '30px',
                background: 'linear-gradient(135deg, #f3f4f6, #f8fafc)',
                borderRadius: '15px',
                // transition: 'box-shadow 0.3s ease',
                // '&:hover': {
                //     boxShadow: '0px 8px 30px rgba(0, 0, 0, 0.2)',
                // }
            }}
        >
            <Divider sx={{ borderColor: '#FF7600', marginBottom: '20px' }} role="presentation">
                <Typography
                    fontSize={{ xs: 24, md: 30 }}
                    fontWeight="600"
                    align="center"
                    variant="h3"
                    sx={{
                        padding: '12px 20px',
                        color: '#32325d',
                        backgroundColor: "#FFFFFF",
                        borderRadius: '25px',
                        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
                        transition: 'transform 0.3s ease',
                        '&:hover': {
                            transform: 'scale(1.02)',
                        },
                    }}
                >
                    {t('main.howItWork.title')}
                </Typography>
            </Divider>
            <Grid container spacing={2} alignItems="stretch">
                {/* Left Block */}
                <Grid item xs={12} md={6}>
                    <Box sx={{ padding: '10px', height: '100%' }}>
                        <Typography
                            fontSize={{ xs: 20, md: 24 }}
                            fontWeight="600"
                            variant="h5"
                            sx={{
                                textAlign: 'center',
                                margin: "15px 0",
                                color: '#32325d',
                                '&:hover': {
                                    color: '#FF7600',
                                },
                            }}
                        >
                            {t('main.howItWork.leftBlock.title')}
                        </Typography>
                        <Typography
                            color="#6B7280"
                            fontSize={{ xs: 14, md: 16 }}
                            variant="subtitle1"
                            component="p"
                            sx={{
                                padding: '0 20px',
                                lineHeight: 1.6,
                            }}
                        >
                            {t('main.howItWork.leftBlock.description')}
                        </Typography>
                    </Box>
                </Grid>

                {/* Divider between sections */}
                <Grid item xs={12} sx={{ display: { xs: 'block', md: 'none' }, textAlign: 'center' }}>
                    <Divider orientation="horizontal" flexItem sx={{ borderColor: '#E5E7EB', margin: '20px 0' }} />
                </Grid>

                {/* Right Block */}
                <Grid item xs={12} md={6}>
                    <Box sx={{ padding: '10px', height: '100%' }}>
                        <Typography
                            fontSize={{ xs: 20, md: 24 }}
                            fontWeight="600"
                            variant="h5"
                            sx={{
                                textAlign: 'center',
                                margin: "15px 0",
                                color: '#32325d',
                                '&:hover': {
                                    color: '#FF7600',
                                },
                            }}
                        >
                            {t('main.howItWork.rightBlock.title')}
                        </Typography>
                        <Typography
                            color="#6B7280"
                            fontSize={{ xs: 14, md: 16 }}
                            variant="subtitle1"
                            component="p"
                            sx={{
                                padding: '0 20px',
                                lineHeight: 1.6,
                            }}
                        >
                            {t('main.howItWork.rightBlock.description')}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};
