import {createSlice} from '@reduxjs/toolkit';

interface messageType {
    updateOffer: boolean
}

const initialState: messageType = {
    updateOffer: false,
};

const offersSlice = createSlice({
    name: 'offers',
    initialState,
    reducers: {
        toggleUpdateOffers: (state) => {
            state.updateOffer = !state.updateOffer;
        },
    },
});

export const {
    toggleUpdateOffers,
} = offersSlice.actions;

export default offersSlice.reducer;
