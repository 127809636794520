import React, {useState} from 'react';
import Box from "@mui/material/Box";
import {CSSTransition, SwitchTransition} from "react-transition-group";
import Crypto_Payment from "../../../public/main/Crypto.jpg";
import {ListPayment} from "./ListPayment/ListPayment";
import {useTranslation} from 'react-i18next';

export const PaymentMethods = () => {
    const [value, setValue] = useState("1");
    const {t, i18n} = useTranslation();

    return (
        <Box sx={{ padding: "15px 0", backgroundColor: "#F9FAFB"}}>
            <SwitchTransition>
                <CSSTransition key={value} timeout={200} classNames="page">
                    <>
                        {value === '1' &&
                            <ListPayment
                                // t={t}
                                header={t('paymentMethods.header')}
                                description={t('paymentMethods.description')}
                                img={Crypto_Payment}
                            />
                        }
                        {
                            /*
                            {value === '2' &&
                            <ListPayment
                                header={"Fiat Payment"}
                                description={"At Rastcom, we offer the best of both worlds when it comes to payments. Alongside\n" +
                                    "                                    cryptocurrency options,\n" +
                                    "                                    we also support a wide range of traditional fiat payment methods, including Visa,\n" +
                                    "                                    Mastercard, PayPal,\n" +
                                    "                                    and more. With us, you can enjoy the convenience and accessibility of these standard\n" +
                                    "                                    payment options,\n" +
                                    "                                    ensuring a seamless experience on our platform."}
                                img={fiat_payment}
                            />
                        }
                             */
                        }
                    </>
                </CSSTransition>
            </SwitchTransition>
            {
                /*

                    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

                  <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons allowScrollButtonsMobile
                  aria-label="scrollable force tabs example"
                  TabIndicatorProps={{style: {display: 'none'}}}
            >
                <Tab
                    sx={{
                        border: value === "1" ? "1px solid #6B7280" : "1px dashed #6B7280",
                        borderRadius: "5px",
                        marginRight: 1,
                        '&.Mui-selected': {
                            color: '#16A34A',
                        },
                    }}
                    value="1"
                    label="Ctypto Payment"
                />
                <Tab
                    sx={{
                        border: value === "2" ? "1px solid #6B7280" : "1px dashed #6B7280",
                        borderRadius: "5px",
                        '&.Mui-selected': {
                            color: '#16A34A',
                        },
                    }}
                    value="1"
                    label="Fiat Payment"
                />
            </Tabs>
                 */
            }
        </Box>
    );
};
