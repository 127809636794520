import { HowToShareLink } from "../page/Faq/HowToShareLink/HowShareLink";
import { Home } from "../page/Home/Home";
import React, { lazy } from "react";


const Login = lazy(() => import('../page/Login/Login').then(module => ({ default: module.Login })));
const SignUp = lazy(() => import('../page/SignUp/SignUp').then(module => ({ default: module.SignUp })));
const Pricing = lazy(() => import('../page/Pricing/Pricing').then(module => ({ default: module.Pricing })));
const PagePasswordRecovery = lazy(() => import('../page/PagePasswordRecovery/PagePasswordRecovery').then(module => ({ default: module.PagePasswordRecovery })));
const About = lazy(() => import('../page/About/About').then(module => ({ default: module.About })));
const ProsInfo = lazy(() => import('../page/ProsInfo/ProsInfo').then(module => ({ default: module.ProsInfo })));
const ContactUs = lazy(() => import('../page/ContactUs/ContactUs').then(module => ({ default: module.ContactUs })));
const Notification = lazy(() => import('../page/Notification/Notification').then(module => ({ default: module.Notification })));
const Faq = lazy(() => import('../page/Faq/Faq').then(module => ({ default: module.Faq })));
const RascoinHome = lazy(() => import('../page/Home/RascoinHome/RascoinHome'));
const Pros = lazy(() => import('../page/Pros/Pros').then(module => ({ default: module.Pros })));
const HowStart = lazy(() => import('../page/Faq/HowStart/HowStart').then(module => ({ default: module.HowStart })));
const HowSetPrices = lazy(() => import('../page/Faq/HowSetPrices/HowSetPrices').then(module => ({ default: module.HowSetPrices })));
const HowHireProfessional = lazy(() => import('../page/Faq/HowHireProfessional/HowHireProfessional').then(module => ({ default: module.HowHireProfessional })));
const WereSeeList = lazy(() => import('../page/Faq/WereSeeList/WereSeeList').then(module => ({ default: module.WereSeeList })));
const HowSubmitReview = lazy(() => import('../page/Faq/HowSubmitReview/HowSubmitReview').then(module => ({ default: module.HowSubmitReview })));



export const publicRout = [
    { id: "/", path: "/", component: <Home /> },
    { id: "login", path: "/login", component: <Login /> },
    { id: "/signup", path: "/signup", component: <SignUp /> },
    { id: "/pricing", path: "/pricing", component: <Pricing /> },
    { id: "/password-recovery", path: "/password-recovery", component: <PagePasswordRecovery /> },
    { id: "/about", path: "/about", component: <About /> },
    { id: "/pros", path: "/pros", component: <Pros /> },
    { id: "/pros/:id", path: "/pros/:id", component: <ProsInfo /> },
    { id: "/notification", path: "/notification", component: <Notification /> },
    { id: "/contact-us", path: "/contact-us", component: <ContactUs /> },
    {
        id: "/FAQ",
        path: "/FAQ",
        component: <Faq />,
        children: [
            { id: "/FAQ/", path: "", component: <HowStart /> },
            { id: "/FAQ/how-start", path: "how-start", component: <HowStart /> },
            { id: "/FAQ/how-set-prices", path: "how-set-prices", component: <HowSetPrices /> },
            { id: "/FAQ/how-hire-professional", path: "how-hire-professional", component: <HowHireProfessional /> },
            { id: "/FAQ/were-see-list", path: "were-see-list", component: <WereSeeList /> },
            { id: "/FAQ/how-submit-review", path: "how-submit-review", component: <HowSubmitReview /> },
            { id: "/FAQ/how-share-link", path: "how-share-link", component: <HowToShareLink /> },
        ]
    },
    { id: "/rascoin", path: "/rascoin", component: <RascoinHome /> },
]