import React, {lazy} from "react";


const Profile = lazy(() => import('../page/Profile/Profile').then(module => ({default: module.Profile})));
const JobDetails = lazy(() => import('../page/JobDetails/JobDetails').then(module => ({default: module.JobDetails})));
const Confirm = lazy(() => import('../page/Confirm/Confirm').then(module => ({default: module.Confirm})));
const Payment = lazy(() => import('../page/Payment/Payment').then(module => ({default: module.Payment})));
const PaymentStripe = lazy(() => import('../page/PaymentStripe/PaymentStripe').then(module => ({default: module.PaymentStripe})));

export const userRouts = [
    {
        id: "/profile",
        path: "/profile",
        component: <Profile/>,
        children: [
            {id: ":id", path: ":id", component: <JobDetails/>}
        ]
    },
    {id: "/confirm", path: "/confirm", component: <Confirm/>},
    {id: "/payment", path: "/payment", component: <Payment/>},
    {id: "/PaymentStripe", path: "/stripe", component: <PaymentStripe/>},
]