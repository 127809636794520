import {Box, MenuItem} from '@mui/material';
import React from 'react';
import Typography from "@mui/material/Typography";
import {SvgIconComponent} from "@mui/icons-material";

interface MyMenuItemProps {
    icon?: SvgIconComponent;
    title: string;
    description: string;
    click?: any
}

export const MyMenuItem: React.FC<MyMenuItemProps> = ({icon: Icon, title, description, click}) => (
    <MenuItem
        sx={{
            '&:hover': {
                borderRadius: '5px',
                cursor: 'pointer'
            },
        }}
        onClick={click}
    >
        <Box sx={{pl: 1}}>
            <Typography sx={{fontWeight: 700, fontSize: "17px", color: "#111827"}}>
                {Icon !== undefined && <Icon sx={{fontSize: "17px"}}/>}{title}
            </Typography>
            <Typography sx={{color: "#6B7280"}} variant="body2">{description}</Typography>
        </Box>
    </MenuItem>
);
