import { Box, Button, Divider, Grid, Card, CardContent, List, ListItem, ListItemText } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useTranslation } from 'react-i18next';
import { motion } from "framer-motion"
import image1 from "../../../../public/main/Rastcom - forBusiness.png"
import image2 from "../../../../public/main/forBusiness2.png"
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import EventIcon from '@mui/icons-material/Event';
import ScheduleIcon from '@mui/icons-material/Schedule';

function ForBusiness() {
    const { t, i18n } = useTranslation();

    return (
        <>
            <Grid container pt={5}
                sx={{
                    display: 'flex',
                    msFlexDirection: 'row',
                    justifyContent: 'center',
                    backgroundColor: "#F9FAFB",
                    alignItems: "center",
                    flexDirection: { xs: 'column', md: "row" },
                    marginBottom: 0,
                    padding: { md: '0px 20px' }
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        textAlign: 'center',
                    }}
                >
                    <Divider
                        sx={{
                            borderBottomColor: '#FF7600',
                            borderBottomWidth: '2px',
                            borderBottomStyle: 'solid'
                        }}
                        role="presentation"
                    >
                        <Typography
                            sx={{
                                fontSize: 26,
                                fontWeight: 600,
                                fontFamily: 'Arial, sans-serif',
                                padding: '20px',
                                color: '#32325d'
                            }}
                        >
                            {t('forBusiness.title')}
                        </Typography>
                    </Divider>
                    <List>
                        <ListItem >
                            <ListItemText secondaryTypographyProps={{ textAlign: 'center', fontSize: '18px', fontWeight: 'bold', color: '#800080', }} secondary={t('forBusiness.secList1-2')} />
                        </ListItem>
                    </List>
                    <Button variant="contained" href={`${i18n.language}/contact-us`} sx={{ mt: 2, fontFamily: 'Arial, sans-serif', }}>
                        {t('forBusiness.requestDemo')}
                    </Button>
                </Box>

                <Grid container
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        backgroundColor: "#F9FAFB",
                        alignItems: "center",
                        flexDirection: { xs: 'column', md: "row" },
                        marginBottom: 0,
                    }}
                >
                    <Grid item md={5} sm={11} xs={11} m={2}>
                        <Typography
                            sx={{
                                fontSize: 24,
                                fontWeight: 600,
                                marginTop: '20px',
                                padding: '10px',
                                color: '#32325d',
                                maxWidth: '100%',
                                wordWrap: 'break-word',
                                textAlign: 'center'
                            }}
                        >
                            {t('forBusiness.subTitle')}
                        </Typography>
                        <List sx={{ width: '100%' }}>
                            <ListItem >
                                <ListItemText secondaryTypographyProps={{ textAlign: 'center', fontSize: '20px', fontWeight: 'bold', color: '#800080', }} secondary={t('forBusiness.secList1')} />
                            </ListItem>
                            {/* <ListItem >
                                <ListItemText secondaryTypographyProps={{ textAlign: 'center', fontSize: '18px', fontWeight: 'bold', color: '#800080', }} secondary={t('forBusiness.secList1-2')} />
                            </ListItem> */}
                        </List>
                        <List sx={{ width: '100%', padding: '10px', }}>
                            <ListItem sx={{ borderLeft: '4px solid #4a90e2', mb: 1 }}>
                                <ListItemText primaryTypographyProps={{ fontSize: '16px', fontWeight: 'bold', color: '#32325d', }} primary={t('forBusiness.uList1')} />
                            </ListItem>
                            <ListItem sx={{ borderLeft: '4px solid #4a90e2', mb: 1 }}>
                                <ListItemText primaryTypographyProps={{ fontSize: '16px', fontWeight: 'bold', color: '#32325d', }} primary={t('forBusiness.uList2')} />
                            </ListItem>
                            <ListItem sx={{ borderLeft: '4px solid #4a90e2' }}>
                                <ListItemText primaryTypographyProps={{ fontSize: '16px', fontWeight: 'bold', color: '#32325d', }} primary={t('forBusiness.uList3')} />
                            </ListItem>
                        </List>
                        {/* <Typography
                            sx={{
                                fontSize: 15,
                                color: "#6B7280",
                                padding: '20px',
                            }}
                        >
                            {t('forBusiness.preDescription')}
                        </Typography> */}
                        <List sx={{ width: '100%' }}>
                            {/* <ListItem >
                                <ListItemText secondaryTypographyProps={{ fontSize: '14px', color: '#32325d', }} secondary={t('forBusiness.secList1')} />
                            </ListItem> */}
                            <ListItem >
                                <ListItemText secondaryTypographyProps={{ fontSize: '14px', color: '#32325d', }} secondary={t('forBusiness.secList2')} />
                            </ListItem>
                            <ListItem >
                                <ListItemText secondaryTypographyProps={{ fontSize: '14px', color: '#32325d', }} secondary={t('forBusiness.secList3')} />
                            </ListItem>
                        </List>
                    </Grid>

                    <Grid item xs={11} md={5} lg={5}
                        sx={{
                            width: '80%',
                            height: 'auto',
                            alignItems: 'center',
                            padding: '10px 0 20px 0',
                            borderRadius: '10px',
                            margin: 'auto'
                        }}
                    >
                        <motion.div
                            initial={{
                                scale: .7
                            }}
                            whileInView={{
                                scale: .9
                            }}
                            transition={{
                                duration: .5
                            }}
                            viewport={{ once: true }}
                        >
                            <img
                                src={image1}
                                style={{
                                    marginTop: "20px",
                                    maxWidth: "100%",
                                    minHeight: "auto"
                                }}
                            />
                        </motion.div>
                    </Grid>

                    {/* New Steps Section with Cards */}
                    <Grid

                        sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}
                    >
                        {/* Card 1 */}
                        <Grid item xs={12} md={4} m={1} >
                            <Card sx={{ padding: { xs: 1, md: 2 }, borderRadius: 2, textAlign: 'center', boxShadow: 3, minHeight: '190px' }}>
                                <CardContent>
                                    <PersonAddIcon color="success" />
                                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#32325d' }}>
                                        {t('forBusiness.step1Title')}
                                    </Typography>
                                    <Typography sx={{ fontSize: 15, color: "#6B7280", mt: 1 }}>
                                        {t('forBusiness.step1Body')}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                        {/* Card 2 */}
                        <Grid item xs={12} md={4} m={1}>
                            <Card sx={{ padding: { xs: 1, md: 2 }, borderRadius: 2, textAlign: 'center', boxShadow: 3, minHeight: '190px' }}>
                                <CardContent>
                                    <EventIcon sx={{ color: 'primary.main' }} />
                                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#32325d' }}>
                                        {t('forBusiness.step2Title')}
                                    </Typography>
                                    <Typography sx={{ fontSize: 15, color: "#6B7280", mt: 1 }}>
                                        {t('forBusiness.step2Body')}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                        {/* Card 3 */}
                        <Grid item xs={12} md={4} m={1}>
                            <Card sx={{ padding: { xs: 1, md: 2 }, borderRadius: 2, textAlign: 'center', boxShadow: 3, minHeight: '190px' }}>
                                <CardContent>
                                    <ScheduleIcon sx={{ color: 'secondary.main' }} />
                                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#32325d' }}>
                                        {t('forBusiness.step3Title')}
                                    </Typography>
                                    <Typography sx={{ fontSize: 15, color: "#6B7280", mt: 1 }}>
                                        {t('forBusiness.step3Body')}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>

                </Grid>

                <Grid
                    container
                    spacing={3}
                    mt={3}
                    md={11}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: "center",
                        flexDirection: { xs: 'column', md: 'row' }, // Change direction based on screen size
                        backgroundColor: "#F9FAFB",
                        marginBottom: 0,
                    }}
                >
                    {/* Image on the left for md and up */}
                    <Grid item xs={11} md={4} lg={4}
                        sx={{
                            width: { xs: '80%', md: '100%' },
                            height: 'auto',
                            alignItems: 'center',
                            padding: '10px 0 20px 0',
                            borderRadius: '10px',
                            margin: 'auto',
                            textAlign: { xs: 'center', md: 'left' }, // Center on mobile, left-align on larger screens
                        }}
                    >
                        <motion.div
                            initial={{ scale: 0.7 }}
                            whileInView={{ scale: 0.9 }}
                            transition={{ duration: 0.5 }}
                            viewport={{ once: true }}
                        >
                            <img
                                src={image2}
                                style={{
                                    marginTop: "20px",
                                    maxWidth: "100%",
                                    minHeight: "auto"
                                }}
                            />
                        </motion.div>
                    </Grid>

                    {/* Text Content on the right for md and up */}
                    <Grid item md={6} sm={11} xs={11} sx={{ ml: { xs: 0, md: 2 }, mt: { xs: 3, md: 0 }, mb: 5 }}>
                        <Divider sx={{ color: '#FF7600' }} role="presentation">
                            <Typography
                                sx={{
                                    fontSize: 26,
                                    fontWeight: 600,
                                    padding: '20px',
                                    color: '#32325d'
                                }}
                            >
                                {t('forBusiness.title2')}
                            </Typography>
                        </Divider>
                        <List sx={{ width: '100%', maxWidth: 360, padding: '10px', }}>
                            <ListItem sx={{ borderLeft: '4px solid #4a90e2', mb: 1 }}>
                                <ListItemText primaryTypographyProps={{ fontSize: '16px', fontWeight: 'bold', color: '#32325d', }} primary={t('forBusiness.uList4')} />
                            </ListItem>
                            <ListItem sx={{ borderLeft: '4px solid #4a90e2', mb: 1 }}>
                                <ListItemText primaryTypographyProps={{ fontSize: '16px', fontWeight: 'bold', color: '#32325d', }} primary={t('forBusiness.uList5')} />
                            </ListItem>
                            <ListItem sx={{ borderLeft: '4px solid #4a90e2' }}>
                                <ListItemText primaryTypographyProps={{ fontSize: '16px', fontWeight: 'bold', color: '#32325d', }} primary={t('forBusiness.uList6')} />
                            </ListItem>
                        </List>
                        <Typography
                            sx={{
                                fontSize: 16,
                                color: '#32325d',
                                padding: '20px',
                            }}
                        >
                            {t('forBusiness.description2')}
                        </Typography>
                    </Grid>
                </Grid>

            </Grid >
        </>
    )
}

export default ForBusiness
