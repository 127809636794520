import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEN from './locales/en.json';
import translationFR from './locales/fr.json';

import pricingEN from './locales/pages/pricing/enPricing.json';
import pricingFR from './locales/pages/pricing/frPricing.json';

import aboutEN from './locales/pages/about/enAbout.json';
import aboutFR from './locales/pages/about/frAbout.json';

import faqEN from './locales/pages/faq/enFaq.json';
import faqFR from './locales/pages/faq/frFaq.json';

import prosEN from './locales/pages/pros/enPros.json';
import prosFR from './locales/pages/pros/frPros.json';

import inputTimeEN from './locales/component/InputTime/enInputTime.json';
import inputTimeFR from './locales/component/InputTime/frInputTime.json';

import rightBlockEN from './locales/pages/profile/rightBlock/enRightBlock.json';
import rightBlockFR from './locales/pages/profile/rightBlock/frRightBlock.json';

import confirInfoEN from './locales/component/confirmInfo/enConfirmInfo.json';
import confirmindoFR from './locales/component/confirmInfo/frConfirmInfo.json';

import generalInformationEN from './locales/pages/profile/generalInformation/enGeneralInformation.json';
import generalInformationFR from './locales/pages/profile/generalInformation/frGeneralInformation.json';

import offersEN from './locales/pages/offers/enOffers.json';
import offersFR from './locales/pages/offers/frOffers.json';

import scheduleCalendarEN from './locales/pages/scheduleCalendar/enScheduleCalendar.json';
import scheduleCalendarFR from './locales/pages/scheduleCalendar/frScheduleCalendar.json';

import setHourlyRateEN from './locales/pages/setHourlyRate/enSetHourlyRate.json';
import setHourlyRateFR from './locales/pages/setHourlyRate/frSetHourlyRate.json';

import offersDetailsEN from './locales/pages/offersDetails/enOffersDetails.json';
import offersDetailsFR from './locales/pages/offersDetails/frOffersDetails.json';

import authEN from './locales/component/auth/enAuth.json';
import authFR from './locales/component/auth/frAuth.json';

import contractEN from './locales/component/contract/enContract.json';
import contractFR from './locales/component/contract/frContract.json';

translationEN.pricing = pricingEN;
translationFR.pricing = pricingFR;

translationEN.about = aboutEN;
translationFR.about = aboutFR;

translationEN.faq = faqEN;
translationFR.faq = faqFR;

translationEN.pros = prosEN;
translationFR.pros = prosFR;

translationEN.component.inputTime = inputTimeEN;
translationFR.component.inputTime = inputTimeFR;

translationEN.profile.rightBlock = rightBlockEN;
translationFR.profile.rightBlock = rightBlockFR;

translationEN.component.confirmInfo = confirInfoEN;
translationFR.component.confirmInfo = confirmindoFR;

translationEN.profile.generalInformation = generalInformationEN;
translationFR.profile.generalInformation = generalInformationFR;

translationEN.profile.offers = offersEN;
translationFR.profile.offers = offersFR;

translationEN.profile.scheduleCalendar = scheduleCalendarEN;
translationFR.profile.scheduleCalendar = scheduleCalendarFR;

translationEN.profile.setHourlyRate = setHourlyRateEN;
translationFR.profile.setHourlyRate = setHourlyRateFR;

translationEN.profile.offersDetails = offersDetailsEN;
translationFR.profile.offersDetails = offersDetailsFR;

translationEN.component.auth = authEN;
translationFR.component.auth = authFR;

translationEN.component.contract = contractEN;
translationFR.component.contract = contractFR;


const resources = {
    en: {
        translation: translationEN
    },
    fr: {
        translation: translationFR
    }
};

i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    //  .use(HttpApi) // завантаження через url
    .init({
        resources,
        fallbackLng: 'en',
        supportedLngs: ['en', 'fr'],
        detection: {
            order: ['path', 'cookie', 'htmlTag', 'localStorage', 'subdomain'],
            caches: ['cookie'],
        },
        interpolation: {
            escapeValue: false
        },
    });

export default i18n;