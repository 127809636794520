import { configureStore } from '@reduxjs/toolkit'
import authSlice from './authSlice/authSlice'
import profileSlice from "./profileSlice/profileSlice";
import jobsSlice from "./jobsSlice/jobsSlice";
import messageSourceSlice from "./messageSourceSlice/messageSource"
import offersSlice from "./offersSlice/offersSlice";
import userProsSlice from "./userPros/userProsSlice";


const preloadedState = localStorage.getItem('auth')
    ? { auth: JSON.parse(localStorage.getItem('auth') || '') }
    : {};

export const store = configureStore({
    reducer: {
        auth: authSlice,
        profile: profileSlice,
        jobs: jobsSlice,
        messageSource: messageSourceSlice,
        offers: offersSlice,
        pros: userProsSlice
    },
    preloadedState
})

store.subscribe(() => {
    localStorage.setItem('auth', JSON.stringify(store.getState().auth));
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
