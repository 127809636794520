import React from 'react';

export const Visa = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="31" fill="none">
            <rect y=".002" width="50" height="30" rx="4" fill="#6B7280"/>
            <path
                d="M36.16 11h-1.72c-.52 0-.92.157-1.16.667L30 19h2.32s.4-.98.48-1.215h2.84c.08.274.28 1.176.28 1.176H38L36.16 11Zm-2.72 5.137c.2-.47.88-2.235.88-2.235 0 .04.2-.47.28-.745l.16.706s.44 1.921.52 2.314h-1.84v-.04ZM30.747 16.333c0 1.6-1.373 2.667-3.506 2.667-.904 0-1.771-.19-2.241-.419l.29-1.752.252.114c.65.305 1.084.419 1.88.419.578 0 1.192-.229 1.192-.762 0-.343-.253-.571-1.048-.952-.759-.381-1.77-.99-1.77-2.095 0-1.524 1.409-2.553 3.397-2.553.759 0 1.41.152 1.807.343l-.29 1.676-.144-.152a3.712 3.712 0 0 0-1.518-.305c-.759.038-1.12.381-1.12.686 0 .343.433.61 1.12.952 1.157.572 1.699 1.22 1.699 2.133Z"
                fill="#fff"/>
            <path d="m12 11.15.039-.15h3.307c.462 0 .808.15.923.636L17 15c-.73-1.794-2.423-3.252-5-3.85Z"
                  fill="#F9B50B"/>
            <path
                d="M22 11.04 18.449 19h-2.408L14 12.333c1.47.902 2.694 2.314 3.143 3.295l.245.823 2.204-5.45H22v.038ZM22.555 11H25l-1.556 8H21l1.555-8Z"
                fill="#fff"/>
        </svg>
    );
};
