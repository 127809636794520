import { Divider, Grid, List, ListItem, ListItemText } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useTranslation } from 'react-i18next';
import ImageSlider from './ImageSlider';
import { motion } from "framer-motion"

function EasOfUse() {
    const { t, i18n } = useTranslation();

    return (
        <Grid container
            sx={{
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: "#F9FAFB",
                alignItems: "center",
                flexDirection: { xs: 'column', md: "row" },
                marginBottom: 0,
            }}
        >
            <Grid item xs={11} md={5} lg={5}
                sx={{
                    width: '80%', // Adjust the width of the grid item containing the slider
                    height: 'auto', // Adjust the height of the grid item containing the slider
                    alignItems: 'center',
                    padding: '10px 0 20px 0',
                    borderRadius: '10px',
                    margin: 'auto'
                }}
            >
                <motion.div
                    initial={{
                        scale: .7
                    }}
                    whileInView={{
                        scale: .9
                    }}
                    transition={{
                        duration: .5
                    }}
                    viewport={{ once: true }}
                >
                    <ImageSlider />
                </motion.div>
            </Grid>

            <Grid item md={6} sm={12} xs={12} m={5}>
                <Divider sx={{ color: '#FF7600' }} role="presentation">
                    <Typography
                        sx={{
                            fontSize: 30,
                            fontWeight: 600,
                            padding: '20px',
                            color: '#32325d'
                        }}
                    >
                        {t('easeOfUse.title')}
                    </Typography>
                </Divider>
                <List sx={{ width: '100%', padding: '10px' }}>
                    <ListItem sx={{
                        mb: 1,
                        padding: '15px',
                        borderRadius: '8px',
                        backgroundColor: '#f5f5f5',
                        transition: 'background-color 0.3s',
                        '&:hover': {
                            backgroundColor: '#e0e0e0',
                        }
                    }}>
                        <ListItemText primaryTypographyProps={{
                            fontSize: '18px',
                            fontWeight: '600',
                            color: '#32325d',
                        }} primary={t('easeOfUse.Ul1')} />
                    </ListItem>
                    <ListItem sx={{
                        mb: 1,
                        padding: '15px',
                        borderRadius: '8px',
                        backgroundColor: '#f5f5f5',
                        transition: 'background-color 0.3s',
                        '&:hover': {
                            backgroundColor: '#e0e0e0',
                        }
                    }}>
                        <ListItemText secondaryTypographyProps={{
                            fontSize: '16px',
                            fontWeight: '600',
                            color: '#32325d',
                        }} secondary={t('easeOfUse.Ul2')} />
                    </ListItem>
                    <ListItem sx={{
                        mb: 1,
                        padding: '15px',
                        borderRadius: '8px',
                        backgroundColor: '#f5f5f5',
                        transition: 'background-color 0.3s',
                        '&:hover': {
                            backgroundColor: '#e0e0e0',
                        }
                    }}>
                        <ListItemText secondaryTypographyProps={{
                            fontSize: '16px',
                            fontWeight: '600',
                            color: '#32325d',
                        }} secondary={t('easeOfUse.Ul3')} />
                    </ListItem>
                </List>


                {/* <Typography
                    sx={{
                        fontSize: 15,
                        color: "#6B7280",
                        padding: '20px',
                    }}
                >
                    {t('easeOfUse.description')}
                </Typography> */}
            </Grid>

        </Grid>
    )
}

export default EasOfUse