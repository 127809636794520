import { ListPayment } from "../PaymentMethods/ListPayment/ListPayment";
import aiAssistant from "../../../public/main/Rastcom-AI.png";
import { useTranslation } from "react-i18next";
import { Box, Divider, Grid, List, ListItem, ListItemText, Typography } from "@mui/material";
import { ImgLoading } from "../../../component/UI/ImgLoading/ImgLoading";
import { motion } from 'framer-motion';

export default function Ai() {
    const { t } = useTranslation();

    return (
        <>
            <Grid mt={5}>
                <motion.div
                    initial={{ scale: 0.7, opacity: 0 }}
                    whileInView={{ scale: 1, opacity: 1 }}
                    transition={{ duration: 0.6 }}
                    viewport={{ once: true }}
                >
                    <Box sx={{
                        display: "flex",
                        justifyContent: { xs: 'center', md: 'space-around' },
                        alignItems: "center",
                        flexDirection: { xs: 'column', md: 'row' },
                        width: "90vw",
                        margin: 'auto',
                        boxShadow: 2,
                        borderRadius: '10px',
                        bgcolor: '#ffffff',
                        p: 3,
                    }}>
                        <Box sx={{ flex: 1 }}>
                            <Typography sx={{ fontSize: 24, fontWeight: 600, color: "#32325d", mb: 2 }}>
                                {t('aiAssistant.header')}
                            </Typography>
                            <List sx={{ width: '100%', maxWidth: 360, padding: '15px', bgcolor: '#f9f9f9', borderRadius: '8px' }}>
                                {[
                                    t('aiAssistant.aiUl1'),
                                    t('aiAssistant.aiUl2'),
                                    t('aiAssistant.aiUl3'),
                                ].map((text, index) => (
                                    <ListItem key={index} sx={{ borderLeft: '4px solid #e94e77', mb: 1, bgcolor: '#fff', borderRadius: '4px', boxShadow: 1 }}>
                                        <ListItemText primaryTypographyProps={{ fontSize: '18px', fontWeight: '600', color: '#4a4a4a' }} primary={text} />
                                    </ListItem>
                                ))}
                            </List>
                        </Box>
                        <Box sx={{
                            minWidth: { xs: '90vw', md: '50vw' },
                            height: "400px",
                            paddingLeft: { xs: '0', md: '50px' },
                        }}>
                            <ImgLoading src={aiAssistant} alt={"AI Assistant"} />
                        </Box>
                    </Box>
                </motion.div>
            </Grid>
            <Box mb={5} sx={{
                padding: '30px',
                borderRadius: '15px',
                bgcolor: '#F9F9F9',
                boxShadow: 1,
            }}>
                <Typography
                    fontWeight="600"
                    sx={{
                        padding: '12px 20px',
                        color: '#32325d',
                        borderRadius: '10px',
                        backgroundColor: '#EAEAEA',
                        marginBottom: '10px',
                        textAlign: 'center',
                    }}
                >
                    {t('aiAssistant.aiInAction')}
                </Typography>
                <Grid container spacing={2} alignItems="stretch">
                    {/* Left Block */}
                    <Grid item xs={12} md={6}>
                        <Box sx={{ padding: '10px', height: '100%', backgroundColor: '#F9F9F9', color: '#32325d', borderRadius: '10px', boxShadow: 1 }}>
                            <Typography
                                fontSize={{ xs: 20, md: 24 }}
                                fontWeight="600"
                                variant="h5"
                                sx={{
                                    textAlign: 'center',
                                    margin: "15px 0",
                                    '&:hover': {
                                        color: '#FF7600',
                                    },
                                }}
                            >
                                {t('aiAssistant.aiLegalTitle')}
                            </Typography>
                            <Typography
                                fontSize={{ xs: 14, md: 16 }}
                                variant="subtitle1"
                                component="p"
                                sx={{
                                    padding: '0 20px',
                                    lineHeight: 1.6,
                                }}
                            >
                                {t('aiAssistant.aiLegalBody')}
                            </Typography>
                        </Box>
                    </Grid>

                    {/* Right Block */}
                    <Grid item xs={12} md={6}>
                        <Box sx={{ padding: '10px', height: '100%', backgroundColor: '#F9F9F9', color: '#32325d', borderRadius: '10px', boxShadow: 1 }}>
                            <Typography
                                fontSize={{ xs: 20, md: 24 }}
                                fontWeight="600"
                                variant="h5"
                                sx={{
                                    textAlign: 'center',
                                    margin: "15px 0",
                                    '&:hover': {
                                        color: '#FF7600',
                                    },
                                }}
                            >
                                {t('aiAssistant.aiTailoredTitle')}
                            </Typography>
                            <Typography
                                fontSize={{ xs: 14, md: 16 }}
                                variant="subtitle1"
                                component="p"
                                sx={{
                                    padding: '0 20px',
                                    lineHeight: 1.6,
                                }}
                            >
                                {t('aiAssistant.aiTailoredBody')}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}
