import React, {FC} from 'react';
import {Box, CircularProgress, Dialog, Divider, Grid} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import MyBtn from "../../../UI/MyBtn/MyBtn";
import Button from "@mui/material/Button";
import PixIcon from "@mui/icons-material/Pix";
import {MenuLink} from "../../../UI/MenuLink/MenuLink";
import {AndroidApp} from "../../../../public/svg/AndroidApp";
import {IosApp} from "../../../../public/svg/IosApp";
import {MyMenuItem} from "../../../UI/MyMenuItem/MyMenuItem";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import {Link} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../store/store";
import {setProsCatFooter} from "../../../../store/userPros/userProsSlice";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import {MyAccordion} from "../../../UI/MyAccordion/MyAccordion";
import useChangeLanguage from "../../../../hooks/useChangeLanguage";
import {useTranslation} from "react-i18next";
import PinDropIcon from '@mui/icons-material/PinDrop';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';

interface MobileMenuType {
    anchorElNav: any
    handleCloseNavMenu: any
    pages: any
}

export const MobileMenu: FC<MobileMenuType> = ({anchorElNav, handleCloseNavMenu, pages}) => {
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const {responseCategoryCity, loadingPros} = useSelector((state: RootState) => state.pros)
    const {role, isAuthenticated} = useSelector((state: RootState) => state.auth);
    const facebookUrl = 'https://www.facebook.com/Rastcom.ca';
    const instagramUrl = "https://www.instagram.com/rastcom.ca";
    const tiktokUrl = "https://www.tiktok.com/@rastcom.ca?is_from_webapp=1&sender_device=pc";
    const linkedInUrl = "https://www.linkedin.com/company/rastcom-ca";
    const youTubeUrl = "https://www.youtube.com/@RastcomCanada"
    const handleClose = () => {
        handleCloseNavMenu(false);
    };

    const changeLanguage = useChangeLanguage();

    return (
        <Dialog
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            fullWidth={true}
            fullScreen={true}
            sx={{ maxWidth: '80%', marginLeft: '20%' }}
            transitionDuration={0}
        >
            <Box
                sx={{
                    padding: "10px"
                }}
            >
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: "10px;",
                    mb: 2,
                }}>
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 2,
                            display: 'flex',
                            alignItems: "center",
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            textDecoration: 'none',
                        }}
                    >
                        <PixIcon/>
                        Rastcom
                    </Typography>
                    <IconButton
                        size="small"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        color="inherit"
                        onClick={changeLanguage}
                        sx={{
                            color: '#374151',
                            display: 'flex',
                            fontSize: "13px",
                            padding: '13px',
                        }}
                    >
                        <LanguageOutlinedIcon/>
                        {i18n.language === "en" ? 'FR' : "EN"}
                    </IconButton>
                    <IconButton color="inherit" onClick={handleCloseNavMenu} aria-label="close">
                        <CloseIcon/>
                    </IconButton>
                </Box>
                <Divider sx={{flexGrow: 1, margin:'15px 0'}}/>
                {pages.map((page: any) => (
                    <Link to={page.path} key={page.id}>
                        <Button
                            onClick={() => {
                                handleCloseNavMenu()
                            }}
                            sx={{
                                fontWeight: 700,
                                fontSize: "17px",
                                color: "#111827",
                                marginLeft: 2.5,
                                width: "100%",
                                justifyContent: "flex-start"
                            }}
                        >
                            {page.name}{ (page.id === '/pros') && <PinDropIcon sx={{  }}/> }
                        </Button>
                    </Link>
                ))}
                 <Divider sx={{flexGrow: 1, margin:'15px 0'}}/>
                <Link to={`/${i18n.language}/contact-us`} onClick={() => handleCloseNavMenu(false)}>
                    <Button variant="outlined" size="small" fullWidth={true}>{t('navBar.contactUs')}</Button>
                </Link>
                <Box mt={2} mb={2}>
                    {
                        role === "admin"
                            ?
                            <Link to={`/${i18n.language}/admin`} onClick={() => handleCloseNavMenu(false)}>
                                <MyBtn mobile={false} fullWidth={true}>
                                    admin
                                </MyBtn>
                            </Link>
                            :
                            null
                    }
                </Box>
                <MyAccordion title={`${t('navBar.services')}`}>
                    <Box>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",
                            backgroundColor: "#F9FAFB",
                            borderRadius: "10px",
                            marginBottom: "10px",
                            padding: "5px",
                        }}>
                            {loadingPros && <CircularProgress/>}
                            {responseCategoryCity?.categories?.map((service: any, index: any) => (
                                <Link
                                    key={index}
                                    to={`/${i18n.language}/pros`}
                                    onClick={() => {
                                        dispatch(setProsCatFooter(service))
                                    }}
                                >
                                    <MyMenuItem click={handleClose} title={service} description=""/>
                                </Link>
                            ))}
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <Divider sx={{flexGrow: 1}}/>
                                <Typography
                                    sx={{
                                        px: 2,
                                        fontSize: "12px",
                                        color: "#6B7280"
                                    }}>{t('navBar.corporateClients')}</Typography>
                                <Divider sx={{flexGrow: 7}}/>
                            </Box>
                            <Link to={`/${i18n.language}/contact-us`}>
                                <MyMenuItem
                                    click={handleClose}
                                    icon={CorporateFareIcon}
                                    title={`${t('navBar.corporate')}`}
                                    description={`${t('navBar.contactUs')}`}
                                />
                            </Link>
                        </Box>
                        <Grid
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                backgroundColor: "#fafafa",
                                border: "1px solid #E5E7EB",
                                borderRadius: "5px",
                                padding: "10px",
                                marginBottom: "10px",
                            }}
                        >
                            <Link to={`${i18n.language}/FAQ`} onClick={handleClose}>
                                <MenuLink text="FAQ"/>
                            </Link>
                            <Link to={`${i18n.language}/contact-us`} onClick={handleClose}>
                                <MenuLink text={`${t('navBar.contactUs')}`}/>

                            </Link>
                            <Divider/>
                            <Typography sx={{px: 2, fontSize: "12px", color: "#6B7280"}}>{i18n.language === "en" ? '(Coming soon...)' : "(A venir...)"}</Typography>
                            <Grid item sx={{
                                display: "flex",
                                marginTop: "10px",
                            }}>
                                <Box sx={{marginRight: "15px"}}>
                                    <AndroidApp/>
                                </Box>
                                <IosApp/>
                            </Grid>
                        </Grid>
                    </Box>
                </MyAccordion>
                {
                    !isAuthenticated &&
                    <Link to={`${i18n.language}/login`}>
                        <Button variant="outlined" fullWidth
                                onClick={() => handleCloseNavMenu(false)}
                                sx={{
                                    color: '#000000',
                                    border: '1px solid #D1D5DB',
                                    textTransform: 'capitalize',
                                    fontSize: 12,
                                    marginTop: "10px",
                                    marginBottom: "10px"
                                }}
                        >
                            {t('navBar.signIn')}
                        </Button>
                    </Link>
                }
                {
                    !isAuthenticated &&
                    <Link to={`/${i18n.language}/signup`}>
                        <MyBtn
                            mobile={false}
                            fullWidth={true}
                            click={() => handleCloseNavMenu(false)}
                        >
                            {t('navBar.singUp')}
                        </MyBtn>
                    </Link>
                }
            </Box>
            <Divider variant="middle" sx={{ marginTop:"10px" }}/>
            <Box
                sx={{
                    padding: "10px"
                }}
            >
                    <Link to={tiktokUrl} target="_blank" rel="noopener noreferrer">
                    <IconButton>
                        <svg
                            style={{fill: '#9CA3AF', width: 20}}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 50 50"
                            width="100%"
                            height="100%"
                        >
                            <path
                                d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z"/>
                        </svg>
                    </IconButton>
                    </Link>
                    <Link to={instagramUrl} target="_blank" rel="noopener noreferrer">
                    <IconButton>
                        <InstagramIcon fontSize="small" style={{color: '#9CA3AF'}}/>
                    </IconButton>
                    </Link>
                    <Link to={linkedInUrl} target="_blank" rel="noopener noreferrer">
                    <IconButton>
                        <LinkedInIcon fontSize="small" style={{color: '#9CA3AF'}}/>
                    </IconButton>
                    </Link>
                    <Link to={facebookUrl} target="_blank" rel="noopener noreferrer">
                    <IconButton>
                        <FacebookIcon fontSize="small" style={{color: '#9CA3AF'}}/>
                    </IconButton>
                    </Link>
                    <Link to={youTubeUrl} target="_blank" rel="noopener noreferrer">
                    <IconButton>
                        <YouTubeIcon fontSize="small" style={{color: '#9CA3AF'}}/>
                    </IconButton>
                    </Link>
                </Box>
        </Dialog>
    );
}