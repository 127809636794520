import {Menu, MenuItem} from '@mui/material';
import React, {FC} from 'react';
import {useDispatch} from "react-redux";
import {logout} from "../../../../store/authSlice/authSlice";
import {useNavigate} from "react-router";
import {Link} from "react-router-dom";
import axios from "axios";
import {useTranslation} from "react-i18next";

interface ProfileMenuType {
    openProfileMenu: null | HTMLElement
    setOpenProfileMenu: (value: null | HTMLElement) => void
}

export const ProfileMenu: FC<ProfileMenuType> = ({openProfileMenu, setOpenProfileMenu}) => {
    const {t, i18n} = useTranslation();
    const history = useNavigate();
    const dispatch = useDispatch();
    const API_URL = process.env.REACT_APP_API_URL;

    const handleCloseProfileMenu = () => {
        setOpenProfileMenu(null);
    };

    const logOut = () => {
        dispatch(logout());
        history(`/${i18n.language}`);
        setOpenProfileMenu(null)
        void axios.post(`${API_URL}/auth/logout`, {},
            {withCredentials: true})
    }

    return (
        <Menu
            id="long-menu"
            anchorEl={openProfileMenu}
            open={Boolean(openProfileMenu)}
            onClose={handleCloseProfileMenu}
            sx={{width: '300px'}}
        >
            <Link to={`${i18n.language}/profile`}>
                <MenuItem sx={{color: "#6B7280"}}>
                    {t('navBar.profile')}
                </MenuItem>
            </Link>
            <MenuItem onClick={logOut}>
                {t('navBar.logOut')}
            </MenuItem>
        </Menu>
    );
};
