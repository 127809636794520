import axios, {AxiosError, AxiosResponse} from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

interface GetInfoParams {
    urlFragment: string;
    setResponse: (data: any) => void;
    setLoading: (loading: boolean) => void;
    setError?: (error: AxiosError) => void | null;
    infoOk?: string
}

export const getInfo = ({urlFragment, setResponse, setLoading, setError, infoOk}: GetInfoParams): void => {
    setLoading(true)
    axios.get(`${API_URL}/${urlFragment}`, {withCredentials: true}
    ).then((response: AxiosResponse) => {
        setResponse(response.data);
        setLoading(false);
        if(infoOk) {console.log(`${infoOk}`)}
    }).catch((error: AxiosError) => {
        setLoading(false);
        if (setError) {
            setError(error);
        }
    });
};
