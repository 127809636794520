import {useTranslation} from "react-i18next";
import linkShare from "../../../public/FAQ/linkShare.png";
import { Grid, List, ListItemText, Typography, useMediaQuery } from '@mui/material';

export const HowToShareLink = () => {
    const {t} = useTranslation();
    const isMobile = useMediaQuery('(max-width:600px)');

    return (
        <>
        <Typography 
                variant="h5" 
                sx={{
                    color:'#16A34A',
                    backgroundColor: "#F9FAFB",
                    fontWeight: 600,
                    padding: '20px',
                }}
        >
                {t('faq.sixth.title')}
        </Typography>
        <Grid container p={5}
            sx={{
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: "#F9FAFB",
                alignItems: "center",
                }} 
        >
            <List>
                <ListItemText primary={t('faq.sixth.li1')}/>
            </List>
            <img
                src={linkShare}
                alt={"FAQ login"}
                style={{ 
                    maxWidth: '100%',
                    width: isMobile ? '100%' : '70%', 
                    height: 'auto',
                    padding:'10px',
                    boxShadow: "0px 4px 20px rgba(192, 192, 192, 0.6), 0px 4px 40px rgba(192, 192, 192, 0.6), 0px 4px 60px rgba(192, 192, 192, 0.6), 0px 4px 80px rgba(192, 192, 192, 0.6)",
                }} 
            />
        </Grid>
        </>
    );
};
