// src/components/RascoinPurchase.tsx
import { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import { Container, TextField, Button, Typography, Box, Alert } from '@mui/material';
import rascoinAbi from './rascoinAbi'; // Import the ABI

// Extend the Window interface to include ethereum
declare global {
  interface Window {
    ethereum: any;
  }
}
const rascoinAddress: string = process.env.REACT_APP_RASCOIN_CONTRACT_ADDRESS || '';
const RASCOIN_PRICE = 0.0003; // Ether per Rascoin

export const Rascoin = () => {
  const [provider, setProvider] = useState<ethers.BrowserProvider | null>(null);
  const [signer, setSigner] = useState<ethers.Signer | null>(null);
  const [account, setAccount] = useState<string | null>(null);
  const [amount, setAmount] = useState<string>('0.03');
  const [rascoinAmount, setRascoinAmount] = useState<string>('100');
  const [notification, setNotification] = useState<string>('');

  useEffect(() => {
    if (window.ethereum) {
      const tempProvider = new ethers.BrowserProvider(window.ethereum)
      setProvider(tempProvider);
    } else {
      setNotification('Please install MetaMask!');
    }
  }, []);

  const connectWallet = async () => {
    if (!provider) {
      setNotification('No provider found!');
      return;
    }
    try {
      await provider.send('eth_requestAccounts', []);
      const tempSignerPromise = provider.getSigner();
      tempSignerPromise.then(tempSigner => {
        tempSigner.getAddress().then(tempAccount => {
          setSigner(tempSigner);
          setAccount(tempAccount);
          setNotification('Wallet connected successfully!');
        });
      });
    } catch (error) {
      setNotification('Failed to connect wallet!');
    }
  };

  const handleResetPurchase = () => {
    setRascoinAmount('100');
    setAmount('0.03');
  };

  const handlePurchase = async () => {
    if (!signer || !account) {
      setNotification('Please connect your wallet!');
      return;
    }
    
    try {
      const rascoinContract = new ethers.Contract(rascoinAddress, rascoinAbi, signer);
      const tx = await rascoinContract.buyTokens(account, { value: ethers.parseEther(amount) });
      await tx.wait();
      setNotification('Purchase successful!');
    } catch (error) {
      let errorMessage = 'Purchase failed! Please check your account balance and try again.';
      if (error instanceof Error && error.message) {
        errorMessage += ` Error: ${error.message}`;
      }
      setNotification(errorMessage);
    }
  };

  const handleRascoinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rascoinValue = e.target.value;
    setRascoinAmount(rascoinValue);
    const etherValue = parseFloat(rascoinValue) * RASCOIN_PRICE;
    setAmount(etherValue.toString());
  };

  return (
    // <Container maxWidth="sm">
      <Box textAlign="center"
        sx={{
            padding:'10px',
            // border:"1px solid #FF7600",
            boxShadow: '0px 0px 2px 1px rgba(150, 150, 150, 0.5)', 
            borderRadius: "10px"
        }}
      >
        <Typography variant="h5" gutterBottom
            sx={{
                color: '#32325d',
                // margin:'0px',
                // background: 'linear-gradient(to right, rgba(255, 215, 180, 1), rgba(255, 215, 180, 0), rgba(255, 240, 210, 0), rgba(255, 240, 210, 1))',
                background: 'linear-gradient(to right, rgba(224, 224, 224, 1), rgba(224, 224, 224, 1), rgba(255, 255, 255, 1), rgba(224, 224, 224, 1))',
                borderRadius: "10px",
                padding: '20px', 
                transition: 'box-shadow 0.3s ease-in-out', // Note the quotes around the property value
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', // Add the desired shadow on hover
            }}
        >
          Purchase Rascoin ( RST )
        </Typography>
        <Typography variant="h6" gutterBottom>
          1 RST = 0.0003 Ether ≈ $1 USD
        </Typography>
        <Button variant="contained" color="primary" onClick={connectWallet}>
          {account ? `Connected: ${account.substring(0, 6)}...${account.substring(account.length - 4)}` : 'Connect Wallet'}
        </Button>
        <Box my={2}
            sx={{
                color: '#32325d',
                // margin:'0px',
                // background: 'linear-gradient(to right, rgba(255, 215, 180, 1), rgba(255, 215, 180, 0), rgba(255, 240, 210, 0), rgba(255, 240, 210, 1))',
                background: 'linear-gradient(to right, rgba(224, 224, 224, 1), rgba(224, 224, 224, 1), rgba(255, 255, 255, 1), rgba(224, 224, 224, 1))',
                borderRadius: "10px",
                padding: '10px', 
                transition: 'box-shadow 0.3s ease-in-out', // Note the quotes around the property value
                boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)', // Add the desired shadow on hover
            }}
        >
          <TextField
            fullWidth
            variant="outlined"
            label="Enter Amount of Rascoin"
            value={rascoinAmount}
            onChange={handleRascoinChange}
          />
        </Box>
        <Box my={2}>
          <Typography>You will pay : {amount} Ether</Typography> 
        </Box>
        <Button sx={{ margin:'5px' }} variant="contained" color="secondary" onClick={handleResetPurchase}>
          Reset
        </Button>
        <Button variant="contained" color="primary" onClick={handlePurchase}>
          Buy Rascoin
        </Button>
        {notification && (
          <Alert severity="info" sx={{ mt: 2 }}>
            {notification}
          </Alert>
        )}
      </Box>
    // </Container>
  );
}







// // src/components/RascoinPurchase.tsx
// import { useState, useEffect } from 'react';
// import { ethers } from 'ethers';
// import { Container, TextField, Button, Typography, Box, Alert } from '@mui/material';
// import rascoinAbi from './rascoinAbi'; // Import the ABI

// // Extend the Window interface to include ethereum
// declare global {
//   interface Window {
//     ethereum: any;
//   }
// }
// const rascoinAddress: string = process.env.REACT_APP_RASCOIN_CONTRACT_ADDRESS || '';

// export const Rascoin = () => {
//   const [provider, setProvider] = useState<ethers.BrowserProvider | null>(null);
//   const [signer, setSigner] = useState<ethers.Signer | null>(null);
//   const [account, setAccount] = useState<string | null>(null);
//   const [amount, setAmount] = useState<string>('');
//   const [notification, setNotification] = useState<string>('');

//   useEffect(() => {
//     if (window.ethereum) {
//       const tempProvider =new ethers.BrowserProvider(window.ethereum)
//       setProvider(tempProvider);
//     } else {
//       setNotification('Please install MetaMask!');
//     }
//   }, []);

//   const connectWallet = async () => {
//     if (!provider) {
//       setNotification('No provider found!');
//       return;
//     }
//     try {
//       await provider.send('eth_requestAccounts', []);
//       const tempSignerPromise = provider.getSigner();
//       tempSignerPromise.then(tempSigner => {
//         tempSigner.getAddress().then(tempAccount => {
//           setSigner(tempSigner);
//           setAccount(tempAccount);
//           setNotification('Wallet connected successfully!');
//         });
//       });
//     } catch (error) {
//       setNotification('Failed to connect wallet!');
//     }
//   };
  

//   const handlePurchase = async () => {
//     if (!signer || !account) {
//       setNotification('Please connect your wallet!');
//       return;
//     }

//     try {
//       const rascoinContract = new ethers.Contract(rascoinAddress, rascoinAbi, signer);
//       const tx = await rascoinContract.buyTokens(account, { value: ethers.parseEther(amount) });
//       await tx.wait();
//       setNotification('Purchase successful!');
//     } catch (error) {
//         let errorMessage = 'Purchase failed! Please check your account balance and try again.';
//         if (error instanceof Error && error.message) {
//             errorMessage += ` Error: ${error.message}`;
//         }
//         setNotification(errorMessage);
//     }
//   };

//   return (
//     <Container maxWidth="sm">
//       <Box my={4} textAlign="center">
//         <Typography variant="h4" gutterBottom>
//           Purchase Rascoin
//         </Typography>
//         <Button variant="contained" color="primary" onClick={connectWallet}>
//           {account ? `Connected: ${account.substring(0, 6)}...${account.substring(account.length - 4)}` : 'Connect Wallet'}
//         </Button>
//         <Box my={2}>
//           <TextField
//             fullWidth
//             variant="outlined"
//             label="Amount in ETH"
//             value={amount}
//             onChange={(e) => setAmount(e.target.value)}
//           />
//         </Box>
//         <Button variant="contained" color="primary" onClick={handlePurchase}>
//           Buy Rascoin
//         </Button>
//         {notification && (
//           <Alert severity="info" sx={{ mt: 2 }}>
//             {notification}
//           </Alert>
//         )}
//       </Box>
//     </Container>
//   );
// }
