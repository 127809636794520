import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import Cookies from "js-cookie";

interface AuthState {
    isAuthenticated: boolean;
    authUserId: string;
    email: string;
    isEmailVerified: boolean;
    role: string;
    isPriceListSet: boolean
    isPhoneNumberVerified: boolean
    isPaymentMethodSet: boolean
    isGovID1Verified: boolean;
    isGovID2Verified: boolean;
    isPro: boolean;
    isDeleted: boolean;
    isSuspended: boolean;
    isSuspendedPro: boolean;
    isRequestForVerification: boolean
}

const localStorageState = localStorage.getItem('auth')
    ? JSON.parse(localStorage.getItem('auth') || '')
    : {};

const initialState: AuthState = {
    isAuthenticated: localStorageState.isAuthenticated || false,
    authUserId: localStorageState.authUserId || '',
    email: localStorageState.email || '',
    isEmailVerified: localStorageState.isEmailVerified || false,
    role: localStorageState.role || '',
    isPriceListSet: localStorageState.isPriceListSet || false,
    isPhoneNumberVerified: localStorageState.isPhoneNumberVerified || false,
    isPaymentMethodSet: localStorageState.isPaymentMethodSet || false,
    isGovID1Verified: localStorageState.isGovID1Verified || false,
    isGovID2Verified: localStorageState.isGovID2Verified || false,
    isPro: localStorageState.isPro || false,
    isDeleted: localStorageState.isDeleted || false,
    isSuspended: localStorageState.isSuspended || false,
    isSuspendedPro: localStorageState.isSuspendedPro || false,
    isRequestForVerification: localStorageState.isRequestForVerification || false,
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login: (state, action: PayloadAction<any>) => {
            state.isAuthenticated = true;
            state.authUserId = action.payload.authUserId;
            state.email = action.payload.email;
            state.isEmailVerified = action.payload.isEmailVerified;
            state.role = action.payload.role;
            state.isPriceListSet = action.payload.isPriceListSet;
            state.isPhoneNumberVerified = action.payload.isPhoneNumberVerified;
            state.isPaymentMethodSet = action.payload.isPaymentMethodSet;
            state.isGovID1Verified = action.payload.isGovID1Verified;
            state.isGovID2Verified = action.payload.isGovID2Verified;
        },
        logout: (state) => {
            state.isAuthenticated = false;
            state.authUserId = "";
            state.email = "";
            state.isEmailVerified = false;
            state.role = '';
            state.isPriceListSet = false;
            state.isPhoneNumberVerified = false;
            state.isPaymentMethodSet = false;
            state.isGovID1Verified = false;
            state.isGovID2Verified = false;
            state.isDeleted = false
            state.isSuspended = false
            state.isSuspendedPro = false
            state.isRequestForVerification = false
            state.isPro = false
            localStorage.removeItem('auth');
            Cookies.remove("token");
        },
        updateStatus: (state, action: PayloadAction<any>) => {
            state.isEmailVerified = action.payload.isEmailVerified;
            state.role = action.payload.role;
            state.isPriceListSet = action.payload.isPriceListSet;
            state.isPhoneNumberVerified = action.payload.phoneNumberVerified;
            state.isPaymentMethodSet = action.payload.isPaymentMethodSet;
            state.isGovID1Verified = action.payload.isGovID1Verified;
            state.isGovID2Verified = action.payload.isGovID2Verified;
            state.isDeleted = action.payload.isDeleted;
            state.isSuspended = action.payload.isSuspended;
            state.isSuspendedPro = action.payload.isSuspendedPro;
            state.isRequestForVerification = action.payload.isRequestForVerification
            state.isPro = action.payload.isPro
        },
        verifyEmail: (state) => {
            state.isEmailVerified = true;
        },
    },
});

export const {login, logout, verifyEmail, updateStatus} = authSlice.actions;

export default authSlice.reducer;
