import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axios, {AxiosResponse} from 'axios';

interface UserState {
    dataProfile: null | UserProfileType;
    statusProfile: string
    loadingProfile: boolean
    errorProfile: boolean | null;
    errorProfileMessage: any | null;
    statusUpdateProfile: boolean
    userId: string
}

const initialState: UserState = {
    dataProfile: null,
    statusProfile: "",
    loadingProfile: false,
    errorProfile: null,
    statusUpdateProfile: false,
    errorProfileMessage: null,
    userId: ""
};

const API_URL = process.env.REACT_APP_API_URL;

export const fetchUser = createAsyncThunk(
    'user/fetchUser',
    () => {
        return axios.get(`${API_URL}/user-profile/single-user/`, {withCredentials: true})
            .then((response: AxiosResponse<any>) => {
                return response.data;
            })
            .catch((error: any) => {
                throw new Error(error.response.data.message);
            });
    }
);
export const updateFetchUser = createAsyncThunk(
    'user/updateFetchUser',
    async (_, {dispatch}) => {
        await axios.get(`${API_URL}/user-profile/single-user/`, {withCredentials: true})
            .then((response: AxiosResponse<any>) => {
                return response.data;
            })
            .catch((error: any) => {
                throw new Error(error.response.data.message);
            });
        return dispatch(fetchUser());
    }
);


export const updateUser = createAsyncThunk(
    'user/updateUser',
    async (updatedUser: any, {dispatch}) => {
        await axios.put(`${API_URL}/user-profile/single-user/`, updatedUser, {
            withCredentials: true
        }).catch((error: any) => {
            throw new Error(error.response.data.message);
        })
        return dispatch(fetchUser());
    }
);

export const updateUserImage = createAsyncThunk(
    'user/updateUserImage',
    async (updatedUser: any, {dispatch}) => {
        await axios.put(`${API_URL}/user-profile/upload/single-user/`, updatedUser, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            withCredentials: true
        }).catch((error: any) => {
            throw new Error(error.response.data.message);
        })
        return dispatch(fetchUser());
    }
);


const profileSlice = createSlice({
    name: 'api',
    initialState: initialState,
    reducers: {
        clearErrorUser: (state) => {
            state.errorProfile = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUser.pending, (state) => {
                state.loadingProfile = true;
                state.errorProfile = false;
                state.errorProfileMessage = null;
            })
            .addCase(fetchUser.fulfilled, (state, action: any) => {
                state.loadingProfile = false
                state.dataProfile = action.payload;
                state.userId = action.payload.userId;
            })
            .addCase(fetchUser.rejected, (state, action: any) => {
                state.loadingProfile = false;
                state.errorProfile = true;
                state.errorProfileMessage = action;
            })
            .addCase(updateUser.pending, (state) => {
                state.loadingProfile = false;
                state.statusUpdateProfile = true
                state.errorProfile = false;
                state.errorProfileMessage = null;
            })
            .addCase(updateUser.fulfilled, (state) => {
                state.loadingProfile = false
                state.statusUpdateProfile = false;
                state.errorProfile = false;
                state.errorProfileMessage = null;
            })
            .addCase(updateUser.rejected, (state, action: any) => {
                state.loadingProfile = false
                state.statusUpdateProfile = false;
                state.errorProfile = true;
                state.errorProfileMessage = action;
            })
            .addCase(updateUserImage.pending, (state) => {
                state.statusUpdateProfile = true
                state.loadingProfile = true
                state.errorProfile = false;
                state.errorProfileMessage = null;
            })
            .addCase(updateUserImage.fulfilled, (state) => {
                state.statusUpdateProfile = false
                state.errorProfile = false;
                state.loadingProfile = false
                state.errorProfile = false;
                state.errorProfileMessage = null;
            })
            .addCase(updateUserImage.rejected, (state, action: any) => {
                state.statusUpdateProfile = false
                state.loadingProfile = false
                state.statusUpdateProfile = false;
                state.errorProfile = true;
                state.errorProfileMessage = action;
            })
            .addCase(updateFetchUser.pending, (state) => {
                state.loadingProfile = true
                state.statusUpdateProfile = true
                state.errorProfile = false;
                state.errorProfileMessage = null;
            })
            .addCase(updateFetchUser.fulfilled, (state) => {
                state.loadingProfile = false
                state.statusUpdateProfile = false;
                state.errorProfile = false;
                state.errorProfileMessage = null;
            })
            .addCase(updateFetchUser.rejected, (state, action: any) => {
                state.loadingProfile = false
                state.statusUpdateProfile = false;
                state.errorProfile = true;
                state.errorProfileMessage = action;
            })


    },
});

export const {clearErrorUser} = profileSlice.actions;

export default profileSlice.reducer;
