import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from "react-redux";
import { store } from "./store/store";
import './i18n';
import { ThemeProvider, createTheme } from '@mui/material';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
const theme = createTheme({
    typography: {
        fontFamily: 'Arial, sans-serif', // Set Arial as the default font
    },
    // You can add other theme customizations here
});
root.render(
    <>
        <React.StrictMode>
            <BrowserRouter>
                <Provider store={store}>
                    <ThemeProvider theme={theme}>
                        <App />
                    </ThemeProvider>
                </Provider>
            </BrowserRouter>
        </React.StrictMode>
    </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
