import React, {FC, ReactNode} from 'react';
import Box from "@mui/material/Box";
import {NavBar} from "./NavBar/NavBar";
import {Footer} from "./Footer/Footer";

interface MainLayoutProps {
    children: ReactNode;
}

export const MainLayout: FC<MainLayoutProps> = ({children}) => {
    return (
        <Box sx={{display: 'flex', flexDirection: 'column', minHeight: '100vh'}}>
            <NavBar/>
            <Box sx={{
                flex: 1,
                maxWidth: '1700px',
                margin: "0 auto",
                width: "100%"
            }}>
                {children}
            </Box>
            <Footer/>
        </Box>
    );
};
