import React from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {CircularProgress, Divider, Grid, List, ListItem, ListSubheader} from '@mui/material';
import IconButton from "@mui/material/IconButton";
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import {AndroidApp} from "../../../public/svg/AndroidApp";
import YouTubeIcon from '@mui/icons-material/YouTube';
import {IosApp} from "../../../public/svg/IosApp";
import FacebookIcon from '@mui/icons-material/Facebook';
import {MasterCard} from "../../../public/svg/MasterCard";
import {Bank} from "../../../public/svg/Bank";
import {Visa} from '../../../public/svg/Visa';
import metaMask from "../../../public/main/MetaMask.png"
import trustWallet from "../../../public/main/Trust_Wallet.png"
import {ImgLoading} from "../../UI/ImgLoading/ImgLoading";
import {Link} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../store/store";
import {setProsCatFooter} from "../../../store/userPros/userProsSlice";
import {useTranslation} from "react-i18next";


export const Footer = () => {
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const {responseCategoryCity, loadingPros} = useSelector((state: RootState) => state.pros)

    const currentYear = new Date().getFullYear();
    const facebookUrl = 'https://www.facebook.com/Rastcom.ca';
    const instagramUrl = "https://www.instagram.com/rastcom.ca";
    const tiktokUrl = "https://www.tiktok.com/@rastcom.ca?is_from_webapp=1&sender_device=pc";
    const linkedInUrl = "https://www.linkedin.com/company/rastcom-ca";
    const youTubeUrl = "https://www.youtube.com/@RastcomCanada"
    const companyInfo = [
        {
            id: Math.random(),
            name: t('footer.blockLinkFirst.aboutUs'),
            link: `${i18n.language}/about`,
        },
        {
            id: Math.random(),
            name: t('footer.blockLinkFirst.servicePlans'),
            link: `${i18n.language}/pricing`,
        },
        {
            id: Math.random(),
            name: t('footer.blockLinkFirst.FAQ'),
            link: `${i18n.language}/FAQ`,
        },
        {
            id: Math.random(),
            name: t('footer.blockLinkFirst.whatNew'),
            link: `${i18n.language}/`,
        },
        {
            id: Math.random(),
            name: t('footer.blockLinkFirst.API'),
            link: `${i18n.language}/`,
        },
        {
            id: Math.random(),
            name: t('footer.blockLinkFirst.contactUs'),
            link: `${i18n.language}/contact-us`,
        },
    ]


    return (
        <Box
            sx={{
                bgcolor: '#F3F4F6',
                color: '#9CA3AF',
                p: 3
            }}
        >
            <Box sx={{
                display: "flex",
                flexWrap: {xs: 'wrap', md: 'nowrap'},
                justifyContent: "space-between",
                flexDirection: {xs: 'column', md: 'row'},
                alignItems: "center"
            }}>
                <Box sx={{display: 'flex', flexWrap: "wrap", width: "100%", justifyContent: "space-around"}}>
                    <List
                        subheader={
                            <ListSubheader
                                sx={{marginBottom: "-5px;", color: "#9CA3AF", backgroundColor: "transparent"}}
                                component="div" id="list-subheader">
                                {t('footer.blockLinkFirst.title')}
                            </ListSubheader>
                        }
                    >
                        {companyInfo.map((product, index) => (
                            <Link to={product.link} key={index}>
                                <ListItem sx={{color: "#6B7280", fontSize: 14,}} key={product.id}>
                                    {product.name}
                                </ListItem>
                            </Link>
                        ))}
                    </List>
                    <List
                        subheader={
                            <ListSubheader
                                sx={{marginBottom: "-5px;", color: "#9CA3AF", backgroundColor: "#F3F4F6"}}
                                component="div" id="list-subheader">
                                {t('footer.blockLinkSecond.cities')}
                            </ListSubheader>
                        }
                        sx={{ maxHeight: '300px', overflowY: 'auto' }}
                    >
                        {loadingPros && <CircularProgress/>}
                        {responseCategoryCity?.cities?.map((service: any) => (
                            // <Link
                            //     key={service}
                            //     to={`${i18n.language}/pros`}
                            //     onClick={() => {
                            //         dispatch(setProsCatFooter(service))
                            //     }}
                            // >
                                <ListItem sx={{color: "#6B7280", fontSize: 14,}}>
                                    {service}
                                </ListItem>
                            // </Link>
                        ))}
                    </List>

                    <List
                        subheader={
                            <ListSubheader
                                sx={{marginBottom: "-5px;", color: "#9CA3AF", backgroundColor: "#F3F4F6"}}
                                component="div" id="list-subheader">
                                {t('footer.blockLinkSecond.categories')}
                            </ListSubheader>
                        }
                        sx={{ maxHeight: '300px', overflowY: 'auto' }}
                    >
                        {loadingPros && <CircularProgress/>}
                        {responseCategoryCity?.categories?.map((service: any) => (
                            <Link
                                key={service}
                                to={`${i18n.language}/pros`}
                                onClick={() => {
                                    dispatch(setProsCatFooter(service))
                                }}
                            >
                                <ListItem sx={{color: "#6B7280", fontSize: 14,}}>
                                    {service}
                                </ListItem>
                            </Link>
                        ))}
                    </List>

                    {/*
                                    <List
                        subheader={
                            <ListSubheader
                                sx={{marginBottom: "-5px;", color: "#9CA3AF", backgroundColor: "transparent"}}
                                component="div" id="list-subheader">
                                Documents
                            </ListSubheader>
                        }
                    >
                        {documents.map((product) => (
                            <ListItem sx={{color: "#6B7280", fontSize: 14,}} key={product.id}>
                                {product.name}
                            </ListItem>
                        ))}
                    </List>
                    */}
                </Box>
                <Box sx={{
                    width: {xs: 'auto', md: '30vw'},
                    marginTop: {xs: '30px', md: '0'},
                }}>
                    <Typography
                        marginBottom="5px"
                        color="#6B7280"
                        fontWeight="600"
                        fontSize={12}
                        variant="subtitle1"
                        component="p"
                        textAlign={"center"}
                    >
                        {t('footer.mobileApp')}
                    </Typography>
                    <Box sx={{
                        display: "flex",
                        justifyContent: "center",
                    }}>
                        <Typography marginRight="15px">
                            <AndroidApp/>
                        </Typography>
                        <Typography marginRight="5px">
                            <IosApp/>
                        </Typography>
                    </Box>
                    <Typography
                        textAlign={"center"}
                        marginBottom="5px"
                        color="#6B7280"
                        fontWeight="600"
                        fontSize={12}
                        variant="subtitle1"
                        component="p">
                        {t('footer.paymentMethods')}
                    </Typography>
                    <Box sx={{
                        marginBottom: '20px',
                    }}
                    >
                        {/* <Box sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexWrap: "wrap",
                            gap: '10px',
                            marginLeft: {xs: '-35px', md: '-12px'}
                        }}>
                            <Box sx={{width: "130px", height: "95px", borderRadius: "5px", marginTop: "-20px"}}>
                                <ImgLoading src={metaMask}/>
                            </Box>
                            <Box sx={{width: "100px", height: "70px", borderRadius: "5px", marginTop: "-8px"}}>
                                <ImgLoading src={trustWallet}/>
                            </Box>
                        </Box> */}
                        <Box sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexWrap: "wrap",
                            gap: '10px',
                        }}>
                            <MasterCard/>
                            <Visa/>
                            <Bank/>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Divider/>
            <Box
                sx={{
                    display: {xs: 'block', md: 'flex',},
                    justifyContent: 'space-around',
                    marginBottom: "15px",
                    marginTop: "20px",
                }}
                role="contentinfo"
            >
                <Typography sx={{width: {xs: '100%', md: '70vw'}}} fontSize={12} variant="subtitle1" component="p">
                    {t('footer.description')}
                </Typography>
                <Box sx={{marginLeft: "15px",}}>
                    < Typography marginBottom="5px" color="#6B7280" fontWeight="600" fontSize={12} variant="subtitle1"
                                 component="p">
                        {t('footer.headOffice')}
                    </Typography>
                    <Typography marginBottom="5px" fontSize={12} variant="subtitle1" component="p">
                        {t('footer.place')}
                    </Typography>
                    <Box marginBottom="5px">
                        {
                            /*
                                          <Typography fontSize={12} variant="subtitle1" component="p">
                            <a style={{color: "#9CA3AF"}} href="tel:+35325266774">
                                <PhoneIcon sx={{fontSize: "15px"}}/> +353 252 66 774
                            </a>
                        </Typography>
                             */
                        }
                        <Typography fontSize={12} variant="subtitle1" component="p">
                            {t('footer.dayWork')}
                        </Typography>
                        <Typography fontSize={12} variant="subtitle1" component="p">
                            09:00 - 17:00
                        </Typography>
                    </Box>
                    <Typography fontSize={12} variant="subtitle1" component="p">
                        {/* <a href={`${i18n.language}/contact-us`} style={{color: '#9CA3AF'}}>
                            {t('footer.contactUs')}
                        </a> */}
                        <Link to={`/${i18n.language}/contact-us`} style={{ color: '#9CA3AF' }}>
                            {t('footer.contactUs')}
                        </Link>
                    </Typography>
                </Box>
            </Box>
            <Divider/>
            <Grid container alignItems="center" justifyContent="space-between">
                <Typography fontSize={12} variant="subtitle1" align="center" color="#9CA3AF" component="p">
                    © {currentYear} Rastcom Canada Incorporated - All rights reserved
                </Typography>
                <Box>
                    <Link to={tiktokUrl} target="_blank" rel="noopener noreferrer">
                    <IconButton>
                        <svg
                            style={{fill: '#9CA3AF', width: 20}}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 50 50"
                            width="100%"
                            height="100%"
                        >
                            <path
                                d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z"/>
                        </svg>
                    </IconButton>
                    </Link>
                    <Link to={instagramUrl} target="_blank" rel="noopener noreferrer">
                    <IconButton>
                        <InstagramIcon fontSize="small" style={{color: '#9CA3AF'}}/>
                    </IconButton>
                    </Link>
                    <Link to={linkedInUrl} target="_blank" rel="noopener noreferrer">
                    <IconButton>
                        <LinkedInIcon fontSize="small" style={{color: '#9CA3AF'}}/>
                    </IconButton>
                    </Link>
                    <Link to={facebookUrl} target="_blank" rel="noopener noreferrer">
                    <IconButton>
                        <FacebookIcon fontSize="small" style={{color: '#9CA3AF'}}/>
                    </IconButton>
                    </Link>
                    <Link to={youTubeUrl} target="_blank" rel="noopener noreferrer">
                    <IconButton>
                        <YouTubeIcon fontSize="small" style={{color: '#9CA3AF'}}/>
                    </IconButton>
                    </Link>
                </Box>
            </Grid>
        </Box>
    );
};
