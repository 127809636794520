import React, {lazy} from "react";


const AdminPage = lazy(() => import('../page/admin/AdminPage/AdminPage').then(module => ({default: module.AdminPage})));
const UsersAdmin = lazy(() => import('../page/admin/AdminPage/UsersAdmin/UsersAdmin').then(module => ({default: module.UsersAdmin})));
const Payments = lazy(() => import('../page/admin/AdminPage/Payment/Payment').then(module => ({default: module.Payments})));
const PaymentDetail = lazy(() => import('../page/admin/AdminPage/Payment/PaymentDetail').then(module => ({default: module.PaymentDetail})));
const JobsAdmin = lazy(() => import('../page/admin/AdminPage/JobsAdmin/JobsAdmin').then(module => ({default: module.JobsAdmin})));
const ProsInfoAdmin = lazy(() => import('../page/admin/AdminPage/UsersAdmin/ProsInfoAdmin/ProsInfoAdmin').then(module => ({default: module.ProsInfoAdmin})));
const JobAdmin = lazy(() => import('../page/admin/AdminPage/UsersAdmin/ProsInfoAdmin/JobAdmin/JobAdmin').then(module => ({default: module.JobAdmin})));


export const adminRouts = [
    {
        id: "/admin",
        path: "/admin",
        component: <AdminPage/>,
        children: [
            {id: "/admin/", path: "", component: <UsersAdmin/>},
            {id: "jobs", path: "jobs", component: <JobsAdmin/>},
            {id: "/admin/:id", path: ":id", component: <ProsInfoAdmin/>},
            {id: "/admin/:id/:jobId", path: ":id/:jobId", component: <JobAdmin/>},
            {id: "payments", path: "payments", component: <Payments/>},
            {id: "payments/:id", path: "payments/:id", component: <PaymentDetail/>},
        ]
    },
]
