import React from 'react';

export const Bank = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="31" fill="none">
            <rect y=".002" width="50" height="30" rx="4" fill="#6B7280"/>
            <path d="M19 15.335h-2v8h2v-8ZM26 15.335h-2v8h2v-8ZM33 15.335h-2v8h2v-8Z" fill="#86A9AF"/>
            <path
                d="M19.333 13.668v1.667c0 .184-.149.333-.333.333h-2a.333.333 0 0 1-.333-.333v-1.667h2.666ZM26.333 13.668v1.667c0 .184-.149.333-.333.333h-2a.333.333 0 0 1-.333-.333v-1.667h2.666ZM33.333 13.668v1.667c0 .184-.149.333-.333.333h-2a.333.333 0 0 1-.333-.333v-1.667h2.666Z"
                fill="#C0DADC"/>
            <path d="M34 12.335v1.333c0 .185-.15.334-.333.334H16.333a.333.333 0 0 1-.333-.334v-1.333h18Z"
                  fill="#86A9AF"/>
            <path
                d="m24.795 5.402-9.305 6.59a.373.373 0 0 0 .205.676h18.61a.372.372 0 0 0 .205-.675l-9.305-6.591a.352.352 0 0 0-.41 0ZM16 23.002h18c.368 0 .667.298.667.667v.666c0 .184-.15.334-.334.334H15.667a.333.333 0 0 1-.334-.334v-.666c0-.369.299-.667.667-.667Z"
                fill="#C0DADC"/>
            <path
                d="M26 10.002a1 1 0 0 0-1-1 .333.333 0 0 1 0-.667.533.533 0 0 1 .395.193.333.333 0 0 0 .543-.386 1.218 1.218 0 0 0-.605-.423v-.384a.333.333 0 0 0-.666 0v.395A.998.998 0 0 0 25 9.668a.333.333 0 1 1 0 .667.533.533 0 0 1-.397-.196.333.333 0 0 0-.541.39c.153.2.364.347.605.422v.384a.333.333 0 1 0 .666 0v-.395a1 1 0 0 0 .667-.938Z"
                fill="#63A63D"/>
        </svg>
    );
};
