import {Typography} from '@mui/material';
import React, {FC, ReactNode} from 'react';

interface MenuLinkType {
    text: ReactNode;
}

export const MenuLink:FC<MenuLinkType> = ({text}) => (
    <Typography
        mt={1}
        sx={{
            color: "#111827",
            fontSize: "14px",
            '&:hover': {
                cursor: "pointer",
                opacity: '0.6',
            },
        }}
    >
        {text}
    </Typography>
);

