import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios, {AxiosResponse} from "axios";

interface jobState {
    dataJob: any;
    statusJob: string
    loadingJob: boolean
    errorJob: boolean | null;
    errorJobMessage: any | null;
    loadingUpdateJob: boolean
}

const initialState: jobState = {
    dataJob: [],
    statusJob: "",
    loadingJob: false,
    errorJob: null,
    loadingUpdateJob: false,
    errorJobMessage: null
};

const API_URL = process.env.REACT_APP_API_URL;

export const fetchJobs = createAsyncThunk(
    'jobs/fetchJobs',
    async () => {
        try {
            const response: AxiosResponse<any> = await axios.get(`${API_URL}/jobs/single`, {withCredentials: true});
            return response.data;
        } catch (error: any) {
            throw new Error(error.response.data.message);
        }
    }
);

const jobsSlice = createSlice({
    name: 'api',
    initialState: initialState,
    reducers: {
        clearErrorJob: (state) => {
            state.errorJob = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchJobs.pending, (state) => {
                state.loadingJob = true
            })
            .addCase(fetchJobs.fulfilled, (state, action: any) => {
                state.loadingJob = false
                state.dataJob = action.payload;
            })
            .addCase(fetchJobs.rejected, (state, action: any) => {
                state.loadingJob = false;
                state.errorJob = true;
                state.errorJobMessage = action;
            })
    },
});


export const {clearErrorJob} = jobsSlice.actions;

export default jobsSlice.reducer;
