import {createSlice, PayloadAction} from '@reduxjs/toolkit';

interface messageType {
    messageSource: any
    notificationSource: number | null
    updateMessage: boolean
}

const initialState: messageType = {
    messageSource: [],
    notificationSource: null,
    updateMessage: false,
};

const messageSourceSlice = createSlice({
    name: 'message',
    initialState,
    reducers: {
        createMessages: (state, action: PayloadAction<any>) => {
            state.messageSource = action.payload
        },
        addMessage: (state, action: PayloadAction<any>) => {
            const index = state.messageSource.findIndex((message: any) => message.jobId === action.payload.jobId);
            if (index >= 0) {
                // обновляємо об'єкт, якщо він уже існує в масиві
                state.messageSource[index] = action.payload;
            } else {
                // додаємо новий об'єкт, якщо він не існує в масиві
                state.messageSource.push(action.payload);
            }
        },
        addNotification: (state, action: PayloadAction<any>) => {
            state.notificationSource = action.payload
        },
        toggleUpdateMessage: (state) => {
            state.updateMessage = !state.updateMessage;
        },
    },
});

export const {
    addMessage,
    createMessages,
    addNotification,
    toggleUpdateMessage
} = messageSourceSlice.actions;

export default messageSourceSlice.reducer;
